/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.login').controller('EulaController', ['$rootScope', 'AppScopeService', '$state', 'AuthenticationResourceFactory', 'loginService', function ($rootScope, appScope, $state, AuthenticationResourceFactory, loginService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.userMsg = ''; // Value to hold any error message that comes back from the server

    /* BINDABLE : METHODS*/

    vm.accept = accept;
    vm.logout = loginService.logout;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */

    /**
     * Return validation message based on provided input field
     * @returns nothing
     */


    function accept() {
      // Make request to change flag
      return AuthenticationResourceFactory.acceptEula({}, function () {
        /*SUCCESS*/
        var sessionData = loginService.getSessionData();
        appScope.setSavedLocation(loginService.getDefaultRoute().url);

        if (sessionData.liteAccount) {
          var routeState = loginService.getDefaultRoute().state;
          $state.go(routeState, {
            newAccount: true
          });
        } else {
          var _routeState = loginService.getDefaultRoute().state;
          $state.go(_routeState, {}, {
            reload: true
          });
        }
      }, function (err) {
        /*FAILURE*/
        switch (err.status) {
          case 401:
            vm.userMsg = 'Unable to accept the EULA';
            break;

          default:
            vm.userMsg = 'Failed to reach server';
        }
      }); // Node will need to "save" the user object via id with the eulaAcceptedFlag set to "1"
      // "save" => /framework/dataObjects/save/json/user?
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {//TODO: Initialize this controller
    }
  }]);
})();