/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.login').controller('RegisterController', ['$rootScope', 'AppScopeService', '$location', '$state', '$mdToast', 'UserResourceFactory', '$scope', 'loginService', 'NotificationService', 'socket', function ($rootScope, appScope, $location, $state, $mdToast, UserResourceFactory, $scope, loginService, NotificationService, socket) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.user = {}; //Have to hard-code the states because we can't access Enums before login

    vm.states = [{
      value: 'AK',
      label: 'Alaska'
    }, {
      value: 'AL',
      label: 'Alabama'
    }, {
      value: 'AR',
      label: 'Arkansas'
    }, {
      value: 'AZ',
      label: 'Arizona'
    }, {
      value: 'CA',
      label: 'California'
    }, {
      value: 'CO',
      label: 'Colorado'
    }, {
      value: 'CT',
      label: 'Connecticut'
    }, {
      value: 'DC',
      label: 'District of Columbia'
    }, {
      value: 'DE',
      label: 'Delaware'
    }, {
      value: 'FL',
      label: 'Florida'
    }, {
      value: 'GA',
      label: 'Georgia'
    }, {
      value: 'HI',
      label: 'Hawaii'
    }, {
      value: 'IA',
      label: 'Iowa'
    }, {
      value: 'ID',
      label: 'Idaho'
    }, {
      value: 'IL',
      label: 'Illinois'
    }, {
      value: 'IN',
      label: 'Indiana'
    }, {
      value: 'KS',
      label: 'Kansas'
    }, {
      value: 'KY',
      label: 'Kentucky'
    }, {
      value: 'LA',
      label: 'Louisiana'
    }, {
      value: 'MA',
      label: 'Massachusetts'
    }, {
      value: 'MD',
      label: 'Maryland'
    }, {
      value: 'ME',
      label: 'Maine'
    }, {
      value: 'MI',
      label: 'Michigan'
    }, {
      value: 'MN',
      label: 'Minnesota'
    }, {
      value: 'MO',
      label: 'Missouri'
    }, {
      value: 'MS',
      label: 'Mississippi'
    }, {
      value: 'MT',
      label: 'Montana'
    }, {
      value: 'NC',
      label: 'North Carolina'
    }, {
      value: 'ND',
      label: 'North Dakota'
    }, {
      value: 'NE',
      label: 'Nebraska'
    }, {
      value: 'NH',
      label: 'New Hampshire'
    }, {
      value: 'NJ',
      label: 'New Jersey'
    }, {
      value: 'NM',
      label: 'New Mexico'
    }, {
      value: 'NV',
      label: 'Nevada'
    }, {
      value: 'NY',
      label: 'New York'
    }, {
      value: 'OH',
      label: 'Ohio'
    }, {
      value: 'OK',
      label: 'Oklahoma'
    }, {
      value: 'OR',
      label: 'Oregon'
    }, {
      value: 'PA',
      label: 'Pennsylvania'
    }, {
      value: 'RI',
      label: 'Rhode Island'
    }, {
      value: 'SC',
      label: 'South Carolina'
    }, {
      value: 'SD',
      label: 'South Dakota'
    }, {
      value: 'TN',
      label: 'Tennessee'
    }, {
      value: 'TX',
      label: 'Texas'
    }, {
      value: 'UT',
      label: 'Utah'
    }, {
      value: 'VA',
      label: 'Virginia'
    }, {
      value: 'VT',
      label: 'Vermont'
    }, {
      value: 'WA',
      label: 'Washington'
    }, {
      value: 'WI',
      label: 'Wisconsin'
    }, {
      value: 'WV',
      label: 'West Virginia'
    }, {
      value: 'WY',
      label: 'Wyoming'
    }];
    vm.provinces = [{
      value: 'AB',
      label: 'Alberta'
    }, {
      value: 'BC',
      label: 'British Columbia'
    }, {
      value: 'MB',
      label: 'Manitoba'
    }, {
      value: 'NB',
      label: 'New Brunswick'
    }, {
      value: 'NL',
      label: 'Newfoundland and Labrador'
    }, {
      value: 'NS',
      label: 'Nova Scotia'
    }, {
      value: 'NT',
      label: 'Northwest Territories'
    }, {
      value: 'NU',
      label: 'Nunavut'
    }, {
      value: 'ON',
      label: 'Ontario'
    }, {
      value: 'PE',
      label: 'Prince Edward Island'
    }, {
      value: 'QC',
      label: 'Quebec'
    }, {
      value: 'SK',
      label: 'Saskatchewan'
    }, {
      value: 'YT',
      label: 'Yukon'
    }];
    vm.countries = [{
      value: 'US',
      label: 'United States'
    }, {
      value: 'CA',
      label: 'Canada'
    }];
    vm.repeatPassword = null;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.register = register;
    vm.checkPasswordRepeat = checkPasswordRepeat;
    vm.disableRegister = disableRegister;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $state.go('login');
    }

    function checkPasswordRepeat() {
      if (vm.user.password !== vm.repeatPassword) {
        $scope.registerForm.repeatPassword.$invalid = true;
      } else {
        $scope.registerForm.repeatPassword.$invalid = false;
      }
    }

    function disableRegister() {
      if ($scope.registerForm) {
        return $scope.registerForm.$invalid || $scope.registerForm.password.$viewValue !== $scope.registerForm.repeatPassword.$viewValue;
      }
    }

    function register() {
      // Keep out those pesky undefined values
      vm.user.address = '';

      if (vm.address1) {
        vm.user.address = vm.user.address + vm.address1;
      }

      if (vm.address2) {
        vm.user.address = vm.user.address + vm.address2;
      }

      UserResourceFactory.registerLite({}, vm.user, function (response) {
        // User has been registered, so log them in and continue
        loginService.login(vm.user.email, vm.user.password).then(function (success) {
          var sessionData = loginService.getSessionData();
          var transferLocation = appScope.getSavedLocation() !== undefined ? appScope.getSavedLocation() : '/submit';
          NotificationService.showNotificationToast('Successfully registered user');
          vm.user = {};
          vm.user.country = 'US';
          vm.user.state = 'AK';
          vm.user.Province = 'AB';
          vm.repeatPassword = null;
          vm.address1 = null;
          vm.address2 = null;
          $scope.registerForm.$setPristine();
          socket.userLevelListen(sessionData.id);
          $location.url(transferLocation);
        }, function (failure) {
          console.log('Something has gone horribly wrong');
        });
      }, function (err) {
        // Failed to register the user
        NotificationService.showNotificationToast(err.data);
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      // Default the country/state/province
      vm.user.country = 'US';
      vm.user.state = 'AK';
      vm.user.Province = 'AB';
    }
  }]);
})();