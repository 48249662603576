/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2016 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.login').controller('LoginController', ['$cookies', '$location', '$rootScope', 'AppScopeService', '$stateParams', 'BrandingResourceFactory', 'loginService', 'socket', '$mdDialog', '$state', function ($cookies, $location, $rootScope, appScope, $stateParams, BrandingResourceFactory, loginService, socket, $mdDialog, $state) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.brandInfo = false;
    vm.brandData = {};
    vm.displayBrandInfo = false;
    vm.liteTypes = ['SPOTLIGHT', 'NBC_SPORTS', 'NBC_OTS', 'TELEMUNDO', 'FOX_RSN', 'CLIC'];
    vm.serverMsg = {
      isError: !!$stateParams.errorMsg,
      message: $stateParams.errorMsg || $stateParams.successMsg || null
    };
    vm.user = {
      username: loginService.lastLoginName(),
      password: ''
    };
    vm.year = new Date().getFullYear();
    /* BINDABLE : METHODS*/

    vm.login = login;
    vm.isBrowserOutOfDate = isBrowserOutOfDate;
    vm.isIe = bowser.msie;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */

    /**
     * Return validation message based on provided input field
     * @returns nothing
     */


    function login() {
      vm.serverMsg.message = null;
      loginService.login(vm.user.username, vm.user.password).then(function (success) {
        var sessionData = loginService.getSessionData();
        vm.serverMsg.isError = false;
        BrandingResourceFactory.getLite({
          override: loginService.getSessionData().destinationType
        }, function (brand) {
          loginService.brandInfo(brand);
          sessionData.userType = loginService.brandInfo().liteBranding && loginService.brandInfo().liteBranding.liteType ? loginService.brandInfo().liteBranding.liteType : '';
          sessionData.clicUser = loginService.brandInfo().liteBranding && loginService.brandInfo().liteBranding.clicUrlFlag ? loginService.brandInfo().liteBranding.clicUrlFlag : false;
          loginService.setSessionData(sessionData);
          socket.userLevelListen(sessionData.id);

          if (sessionData.franchiseCustomer && appScope.getSavedLocation() === '/deliveries/list') {
            appScope.setSavedLocation('/deliveries/promos');
          }

          _redirect();
        }, function () {
          // ignore if we failed to get branding and redirect anyway
          _redirect();
        });
      }, function (failure) {
        // All login error logic has been moved to Node.
        // Ripley's only job is to recognize an error and display the resulting message.
        vm.serverMsg.isError = true;
        vm.serverMsg.message = failure;
      });
    }

    function isBrowserOutOfDate() {
      if (bowser.msie) {
        return bowser.version <= 10;
      } else if (bowser.chrome) {
        return false;
      } else if (bowser.msedge) {
        return false;
      } else if (bowser.firefox) {
        return false;
      } else if (bowser.safari) {
        return bowser.version <= 9;
      } else {
        // Everything else is not supported
        return true;
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function _showUnsupportedDialog() {
      var confirm = $mdDialog.confirm().title('Unsupported Browser.').textContent('Internet Explorer is no longer supported.  Please upgrade to Microsoft Edge or another supported browser.').ariaLabel('Internet Explorer is no longer supported.  Please upgrade to Microsoft Edge or another supported browser.').ok('OK');
      $mdDialog.show(confirm).then(function () {
        //User hit "Ok" -- the only option we give them in this scenario
        var url = $state.href('browserSupport', {});
        window.open(url, '_blank');
      });
    }

    function _redirect() {
      var defaultRedirect = loginService.getDefaultRoute().url; // Move user on to the correct location

      var transferLocation = appScope.getSavedLocation() !== undefined ? appScope.getSavedLocation() : defaultRedirect;
      $location.url(transferLocation);
    } // function _showIntroDialog() {
    //     var showLiteMessage = false;
    //     if (
    //         vm.brandData &&
    //         vm.brandData.liteBranding &&
    //         vm.brandData.liteBranding.liteType
    //     ) {
    //         // If their "lite" branding is one of the hard-coded lite types, then display one message.  otherwise, display other message.
    //         // Need to do it this way now because we are storing non-lite URLs (such as BMW) in the "lite" branding table.
    //         if (vm.liteTypes.includes(vm.brandData.liteBranding.liteType)) {
    //             showLiteMessage = true;
    //         }
    //     }
    //     var intro = introJs();
    //     var introTemplate = showLiteMessage
    //         ? "<div class='intro-container'>" +
    //           "<div class='intro-header'><p>Welcome to the new look!</p></div>" +
    //           "<div class='intro-body'>" +
    //           "<p class='intro-text'>Your current credentials will get you logged into your account or you can register for a new account.</p>" +
    //           "<p class='intro-text'>If you should have any questions once logged in, please refer to the <i class='far fa-question-circle'></i> Icon at the top right of the screen for user guides and support related FAQs.</p>" +
    //           '</div>' +
    //           '</div>'
    //         : "<div class='intro-container'>" +
    //           "<div class='intro-header'><p>Welcome to the new look!</p></div>" +
    //           "<div class='intro-body'>" +
    //           "<p class='intro-text'>Your current credentials will get you logged into your account.</p>" +
    //           "<p class='intro-text'>If you should have any questions once logged in, please reach out to your account manager.  " +
    //           "Coming soon, the <i class='far fa-question-circle'></i> icon will hold valuable user information, so keep checking back in and Welcome to The New AdPlatform!</p>" +
    //           '</div>' +
    //           '</div>';
    //
    //     intro.setOptions({
    //         tooltipClass: 'pre-login-intro',
    //         showStepNumbers: false,
    //         showBullets: false,
    //         doneLabel: 'Okay, thanks!',
    //         exitOnOverlayClick: false,
    //         exitOnEsc: false,
    //         steps: [
    //             {
    //                 intro: introTemplate,
    //             },
    //         ],
    //     });
    //     intro.start();
    //     // Dismiss the modal until they clear their cookies
    //     intro.onexit(function () {
    //         $cookies.put('comcast.addelivery.newlookdialog', 'DISMISSED');
    //     });
    // }

    /**
     * Initialization for this controller.
     */


    function _init() {
      BrandingResourceFactory.getLite({}, function (brand) {
        //success
        vm.brandInfo = brand;
        vm.brandData = brand.data;

        if (vm.brandInfo.image && !(vm.brandInfo.image === '')) {
          vm.brandInfo.loginImage = vm.brandInfo.image;
          vm.displayBrandInfo = true;
        } else if (vm.brandInfo.headerImage && vm.brandInfo.headerImage.data && vm.brandInfo.headerImage.data !== '') {
          vm.brandInfo.loginImage = vm.brandInfo.headerImage;
          vm.displayBrandInfo = true;
        }
      }, function noImage() {
        vm.brandInfo = undefined;
      });

      if (bowser.msie) {
        _showUnsupportedDialog();
      }
    }
  }]);
})();