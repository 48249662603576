/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.login').controller('ResetController', ['$state', 'AuthenticationResourceFactory', function ($state, AuthenticationResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.userMsg = ''; // Value to hold any error message that comes back from the server

    vm.resetEmail = '';
    /* BINDABLE : METHODS*/

    vm.reset = reset;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */

    /**
     * Return validation message based on provided input field
     * @returns nothing
     */


    function reset() {
      // Don't even bother trying if there is no email
      if (vm.resetEmail === null) {
        return;
      } // Clear out any stale error messages


      vm.userMsg = '';
      AuthenticationResourceFactory.resetPassword({}, {
        email: vm.resetEmail
      }, function () {
        /*SUCCESS*/
        $state.go('login', {
          successMsg: 'Password Reset Initiated'
        });
      }, function (err) {
        /*FAILURE*/
        switch (err.status) {
          case 401:
            vm.userMsg = 'Unable to find user information for email provided';
            break;

          default:
            vm.userMsg = 'Failed to reach server';
        }

        $state.go('login', {
          errorMsg: vm.userMsg
        });
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {//TODO: Initialize this controller
    }
  }]);
})();